import {
  LOADING,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  BATTEL_LIST_ADMIN,
  WINNING_REQ,
  WITHDRAWAL_LIST,
  KYS_LIST,
  GET_TRANSACTION_LISTS,
  USER_DETAILS,
  BATTEL_DETAILS,
  GET_SETTING,
  GET_SUB_CATEGORY,
  GET_MAIN_ALL_CATEGORY,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_PRODUCT_LISTS,
  ORDER_LIST,
  GET_INVOICE_DETAILS,
  ORDER_DETAILS,
  MQ_CHALENGE,
  DUQE_CHALENGE,
  MONTH_REPORT,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_SUB_SUB_CATEGORY_LISTS,
  GET_PRODUCT_DETAILS,
  MONTH_REPORT_2,
  GET_SELLER_PROFILE,
  TOP_VIEW_ALL_TYPE,
  SLIDER_LIST,
  DAYNEMIC_BTN,
  LOST_AND_FOUND,
  GET_PACKAGE_LIST,
  GET_VIDEO_LISTS,
  VENDOR_APYMENT_LIST,
  VENDOR_APYMENT_DETAILS,
  EDU_CATE,
  EDU_CATE_ALL,
  EDU_ALL,
  GET_MARQUES,
  EXAM_LIST,
  GET_CERTIFICATE_LIST,
  GET_LISTING_LIST,
  GET_EDU_DETAILS_LIST,
  FOOTER_PAGE_TYPE,
  BLOG,
  GET_PAGES,
  FOOTER_PAGE_LIST_SELECT,
  FOOTER_PAGE_LIST,
  REFFERAL_PROGRAM_LIST,
  GET_ALL_BRAND,
  GET_LISTING_DETAILS,
} from "../common/constant";

const initialState = {
  loading: false,
  categories_delete: {},
  users_list: {},
  users_contact_list: {},
  category_list: {},
  quiz_list: {},
  question_list: {},
  deshboard: {},
  battels_list_admin: {},
  winning_req: {},
  withdrwarl_list: {},
  kyc_list: {},
  get_transaction_list: {},
  user_details: {},
  battels_details: {},
  dynemic_btn: {},
  get_setting: {},
  get_sub_category: {},
  get_sub_sub_category: {},
  get_product_list: {},
  order_details: {},
  mq_chelenge: {},
  duqe_chelenge: {},
  get_ticket_list: {},
  get_product_details: {},
  month_report_2: {},
  get_seller_profile: {},
  lost_and_found: {},
  get_package_list: {},
  vendor_payment_List: {},
  get_video_list: {},
  vendor_payment_details: {},
  get_edu_all: {},
  edu_cate: {},
  get_marques: {},
  get_exam_list: {},
  get_listing_list: {},
  get_certificate_list: {},
  get_ledu_details_list: {},
  get_blog: {},
  referral_program_list: {},
  get_listing_details: {},
  faqs_list: [],
  get_message_list: [],
  month_report: [],
  get_main_all_category: [],
  get_sub_sub_all_category: [],
  get_sub_sub_category_list: [],
  get_category_sale: [],
  edu_cate_all: [],
  footer_page_type: [],
  footer_page_list_select: [],
  footer_page_list: [],
  grt_all_brand: [],
};

const allapireducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case USERS_LIST:
      return { ...state, users_list: action.payload };
    case USERS_CONTACT_LIST:
      return { ...state, users_contact_list: action.payload };
    case CATEGORY_LIST:
      return { ...state, category_list: action.payload };
    case QUIZ_LIST:
      return { ...state, quiz_list: action.payload };
    case QUESTION_LIST:
      return { ...state, question_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case BATTEL_LIST_ADMIN:
      return { ...state, battels_list_admin: action.payload };
    case WINNING_REQ:
      return { ...state, winning_req: action.payload };
    case WITHDRAWAL_LIST:
      return { ...state, withdrwarl_list: action.payload };
    case KYS_LIST:
      return { ...state, kyc_list: action.payload };
    case GET_TRANSACTION_LISTS:
      return { ...state, get_transaction_list: action.payload };
    case USER_DETAILS:
      return { ...state, user_details: action.payload };
    case BATTEL_DETAILS:
      return { ...state, battels_details: action.payload };
    case GET_SETTING:
      return { ...state, get_setting: action.payload };
    case GET_SUB_CATEGORY:
      return { ...state, get_sub_category: action.payload };
    case GET_MAIN_ALL_CATEGORY:
      return { ...state, get_main_all_category: action.payload };
    case GET_SUB_CATEGORY_ALL_LISTS:
      return { ...state, get_sub_sub_all_category: action.payload };
    case GET_SUB_SUB_CATE_LIST_FIL:
      return { ...state, get_sub_sub_category: action.payload };
    case GET_PRODUCT_LISTS:
      return { ...state, get_product_list: action.payload };
    case GET_INVOICE_DETAILS:
      return { ...state, get_invoice_details: action.payload };
    case ORDER_LIST:
      return { ...state, order_list: action.payload };
    case ORDER_DETAILS:
      return { ...state, order_details: action.payload };
    case MQ_CHALENGE:
      return { ...state, mq_chelenge: action.payload };
    case DUQE_CHALENGE:
      return { ...state, duqe_chelenge: action.payload };
    case MONTH_REPORT:
      return { ...state, month_report: action.payload };
    case GET_SELLER_TICKET_LIST:
      return { ...state, get_ticket_list: action.payload };
    case GET_MESSAGE_LISTS:
      return { ...state, get_message_list: action.payload };
    case GET_SUB_SUB_CATEGORY_LISTS:
      return { ...state, get_sub_sub_category_list: action.payload };
    case GET_PRODUCT_DETAILS:
      return { ...state, get_product_details: action.payload };
    case MONTH_REPORT_2:
      return { ...state, month_report_2: action.payload };
    case GET_SELLER_PROFILE:
      return { ...state, get_seller_profile: action.payload };
    case TOP_VIEW_ALL_TYPE:
      return { ...state, get_category_sale: action.payload };
    case SLIDER_LIST:
      return { ...state, slider_list: action.payload };
    case DAYNEMIC_BTN:
      return { ...state, dynemic_btn: action.payload };
    case LOST_AND_FOUND:
      return { ...state, lost_and_found: action.payload };
    case GET_PACKAGE_LIST:
      return { ...state, get_package_list: action.payload };
    case GET_VIDEO_LISTS:
      return { ...state, get_video_list: action.payload };
    case VENDOR_APYMENT_LIST:
      return { ...state, vendor_payment_List: action.payload };
    case VENDOR_APYMENT_DETAILS:
      return { ...state, vendor_payment_details: action.payload };
    case EDU_CATE:
      return { ...state, edu_cate: action.payload };
    case EDU_CATE_ALL:
      return { ...state, edu_cate_all: action.payload };
    case EDU_ALL:
      return { ...state, get_edu_all: action.payload };
    case GET_MARQUES:
      return { ...state, get_marques: action.payload };
    case EXAM_LIST:
      return { ...state, get_exam_list: action.payload };
    case GET_CERTIFICATE_LIST:
      return { ...state, get_certificate_list: action.payload };
    case GET_LISTING_LIST:
      return { ...state, get_listing_list: action.payload };
    case GET_EDU_DETAILS_LIST:
      return { ...state, get_ledu_details_list: action.payload };
    case FOOTER_PAGE_TYPE:
      return { ...state, footer_page_type: action.payload };
    case BLOG:
      return { ...state, get_blog: action.payload };
    case GET_PAGES:
      return { ...state, get_Page_list_Admin: action.payload };
    case FOOTER_PAGE_LIST_SELECT:
      return { ...state, footer_page_list_select: action.payload };
    case FOOTER_PAGE_LIST:
      return { ...state, footer_page_list: action.payload };
    case REFFERAL_PROGRAM_LIST:
      return { ...state, referral_program_list: action.payload };
      case GET_ALL_BRAND:
        return { ...state, grt_all_brand: action.payload };
        case GET_LISTING_DETAILS:
          return { ...state, get_listing_details: action.payload };
    default:
      return state;
  }
};

export default allapireducer;
