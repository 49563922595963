import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

function Pages() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const users_list = useSelector((state) =>
    state?.allapi?.get_Page_list_Admin ? state?.allapi?.get_Page_list_Admin : {}
  );
  const listdata = users_list?.docs ? users_list?.docs : [];
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];
  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");
  const hendleDelete = (e) => {
    setShowDelete(false);
    dispatch(allapiAction.deletePage({ id: e, page: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };
  const [cateDetail, setcateDetail] = useState({});
  const [images, setImages] = useState([]);
  const maxNumber = 1;
  const [Imagesfe, setImagesfe] = useState("");
  const onChangefe = (imageList, addUpdateIndex) => {
    setImagesfe(imageList);
  };
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };
  const [aboutPageUpdate, setaboutPageUpdate] = useState("");
  const showModal = (e) => {
    setcateDetail(e);
    setaboutPageUpdate(e?.containt);

    setIsModalOpen(true);
  };
  const handleOk = () => {
    const data = new FormData();
    data.append("title", cateDetail?.title);
    data.append("description", aboutPageUpdate);
    data.append("meta_description", cateDetail?.meta_description);
    data.append("short_description", cateDetail?.short_description);
    data.append("meta_title", cateDetail?.meta_title);
    data.append("_id", cateDetail?._id);
    data.append("page", page);
    cateDetail.containt = aboutPageUpdate;
    cateDetail.page = page;
    dispatch(allapiAction.updatePageAdmin(cateDetail));
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [cateDetailAdd, setcateDetailAdd] = useState({});
  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };
  const showModalAdd = (e) => {
    setcateDetail(e);
    setAddModalOpen(true);
  };
  const [aboutPage, setaboutPage] = useState("");
  const handleOkAdd = () => {
    if (!cateDetailAdd?.title) {
      toast.error("please enter title");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("title", cateDetailAdd?.title);
      data.append("description", aboutPage);
      data.append("meta_title", cateDetailAdd?.meta_title);
      data.append("meta_description", cateDetailAdd?.meta_description);
      data.append("short_description", cateDetailAdd?.short_description);
      cateDetailAdd.containt = aboutPage;
      dispatch(allapiAction.createAbout(cateDetailAdd));

      setcateDetailAdd({ name: "", link: "" });
      setAddModalOpen(false);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.getPagesm(1));

    return () => {};
  }, []);

  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.getPagesm(1));

      return () => {};
    }
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.getPagesm(e));

      return () => {};
    }
  };

  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      console.log(uploadAdapter(loader));
      return uploadAdapter(loader);
    };
  }
  return (
    <div>
      {/* update */}
      <Modal
        title="Update "
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <div style={{ minHeight: "320px" }}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="name"
              name="title"
              value={cateDetail?.title}
              onChange={(e) => {
                hendleTochangeCategory(e);
              }}
            />
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="meta title"
              name="metaTag"
              value={cateDetail?.metaTag}
              onChange={(e) => {
                hendleTochangeCategory(e);
              }}
            />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="meta description"
              name="metakeyword"
              value={cateDetail?.metakeyword}
              onChange={(e) => {
                hendleTochangeCategory(e);
              }}
            />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="short description"
              name="metadescription"
              value={cateDetail?.metadescription}
              onChange={(e) => {
                hendleTochangeCategory(e);
              }}
            />
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="mt-10">
              <CKEditor
                editor={Editor}
                data={aboutPageUpdate}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setaboutPageUpdate(data);
                }}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
              />
            </div>
          </Col>
        </div>
      </Modal>
      <Modal
        title="Create "
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        width={1000}
      >
        <div style={{ minHeight: "320px" }}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="Title"
              name="title"
              value={cateDetailAdd?.title}
              onChange={(e) => {
                hendleTochangeCategoryAdd(e);
              }}
            />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="meta title"
              name="metaTag"
              value={cateDetailAdd?.metaTag}
              onChange={(e) => {
                hendleTochangeCategoryAdd(e);
              }}
            />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="meta description"
              name="metakeyword"
              value={cateDetailAdd?.metakeyword}
              onChange={(e) => {
                hendleTochangeCategoryAdd(e);
              }}
            />
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <input
              autoComplete="new-off"
              className="form-control mt-8"
              placeholder="short description"
              name="metadescription"
              value={cateDetailAdd?.metadescription}
              onChange={(e) => {
                hendleTochangeCategoryAdd(e);
              }}
            />
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="mt-10">
              <CKEditor
                editor={Editor}
                data={aboutPage}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setaboutPage(data);
                }}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                //             editor.editing.view.change( writer => {
                //     writer.setStyle( 'height', '200px', editor.editing.view.document.getRoot() );
                // } );
              />
            </div>
          </Col>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Pages"}
            subHeading={`Total ${users_list?.totalDocs || 0} Pages`}
          />
        </div>
        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Pages;
